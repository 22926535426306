import SvgIcon from '@/components/SvgIcon.vue';
import { ssoLoginFetch } from '@/api/login';
export default {
  components: {
    SvgIcon
  },
  created() {
    if (this.$route.query && this.$route.query.isSwitchAccount === '1') {
      this.againLogin();
    }
  },
  methods: {
    againLogin() {
      sessionStorage.removeItem('userInfo');
      ssoLoginFetch();
    }
  }
};